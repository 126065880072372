import { Link } from '@remix-run/react'
import cn from 'classnames'

import DevelopmentOnly from '@/components/DevelopmentOnly'
import { track } from '@/utils/analytics.client'

type Props = {
  isFullWidth?: boolean
}

export default function SiteFooter({ isFullWidth }: Props) {
  return (
    <footer
      className={cn('my-6 px-3 sm:px-5', {
        'container max-w-7xl': !isFullWidth
      })}
    >
      <nav
        className={cn('grid grid-cols-1 text-left sm:grid-cols-2 lg:grid-cols-5', {
          'lg:mx-7': !isFullWidth
        })}
      >
        <div className='dashed-box dashed-box-x-t sm:dashed-box-l-t lg:dashed-box-y-l p-3'>
          <h2 className='font-semibold'>Company</h2>

          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/about'
          >
            About
          </Link>
          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/blog'
          >
            Blog
          </Link>
          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/changelog'
          >
            Changelog
          </Link>
          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/careers'
          >
            Careers
          </Link>
        </div>

        <div className='dashed-box dashed-box-x-t lg:dashed-box-y-l p-3'>
          <h2 className='font-semibold'>Product</h2>

          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/case-studies'
          >
            Case studies
          </Link>
          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/enterprise'
          >
            Enterprise
          </Link>
          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/pricing'
          >
            Pricing
          </Link>
        </div>

        <div className='dashed-box dashed-box-x-t sm:dashed-box-l-t lg:dashed-box-y-l p-3'>
          <h2 className='font-semibold'>Resources</h2>

          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/docs'
          >
            Documentation
          </Link>
          <a
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            href='https://support.planetscale.com/hc/en-us'
            onClick={trackLinkClick}
            rel='nofollow noopener noreferrer'
            target='_blank'
          >
            Support
          </a>
          <a
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            href='https://planetscalestatus.com'
            onClick={trackLinkClick}
            rel='nofollow noopener noreferrer'
            target='_blank'
          >
            Status
          </a>
          <a
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            href='https://trust.planetscale.com'
            onClick={trackLinkClick}
            rel='nofollow noopener noreferrer'
            target='_blank'
          >
            Trust Center
          </a>
        </div>

        <div className='dashed-box dashed-box-x-t lg:dashed-box-y-l p-3'>
          <h2 className='font-semibold'>Courses</h2>

          <Link
            className='block pl-1 -indent-1 text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/learn/courses/database-scaling/introduction/course-introduction'
          >
            Database Scaling
          </Link>
          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/learn/courses/vitess'
          >
            Learn Vitess
          </Link>
          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/learn/courses/mysql-for-developers'
          >
            MySQL for Developers
          </Link>
        </div>

        <div className='dashed-box p-3 sm:col-span-2 lg:col-span-1'>
          <h2 className='font-semibold text-primary hover:text-contrast'>Open source</h2>

          <Link
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            onClick={trackLinkClick}
            prefetch='intent'
            to='/vitess'
          >
            Vitess
          </Link>
          <a
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            href='https://vitess.io/slack'
            onClick={trackLinkClick}
            rel='nofollow noopener noreferrer'
            target='_blank'
          >
            Vitess community
          </a>
          <a
            className='block pl-1ch -indent-1ch text-primary hover:text-contrast'
            href='https://github.com/planetscale'
            onClick={trackLinkClick}
            rel='me nofollow noopener noreferrer'
            target='_blank'
          >
            GitHub
          </a>
        </div>
      </nav>

      <div
        className={cn('dashed-box dashed-box-x-b p-3', {
          'lg:mx-7': !isFullWidth
        })}
      >
        <p className='mb-3 md:mb-0'>
          <Link className='text-primary' prefetch='intent' rel='nofollow' to='/legal/privacy'>
            Privacy
          </Link>
          <span className='text-decoration' role='presentation'>
            {' '}
            |{' '}
          </span>
          <Link className='text-primary' prefetch='intent' rel='nofollow' to='/legal/siteterms'>
            Terms
          </Link>
          <span className='text-decoration' role='presentation'>
            {' '}
            |{' '}
          </span>
          <Link className='text-primary' prefetch='intent' rel='nofollow' to='/legal/cookies'>
            Cookies
          </Link>
          <span className='text-decoration' role='presentation'>
            {' '}
            |{' '}
          </span>
          <Link
            className='text-primary'
            prefetch='intent'
            rel='nofollow'
            to='/legal/privacy#privacy-rights-and-choices'
          >
            Do Not Share My Personal Information
          </Link>
        </p>
        <p className='text-secondary'>&copy; {new Date().getFullYear()} PlanetScale, Inc. All rights reserved.</p>
      </div>

      <p
        className={cn('mb-0 mt-3 break-normal', {
          'lg:mx-7': !isFullWidth
        })}
      >
        <a
          className='text-primary'
          href='https://github.com/planetscale'
          rel='me nofollow noopener noreferrer'
          target='_blank'
        >
          GitHub
        </a>
        <span className='text-decoration' role='presentation'>
          {' '}
          |{' '}
        </span>
        <a
          aria-label='X (formerly Twitter)'
          className='text-primary'
          href='https://twitter.com/planetscale'
          rel='me nofollow noopener noreferrer'
          target='_blank'
        >
          X
        </a>
        <span className='text-decoration' role='presentation'>
          {' '}
          |{' '}
        </span>
        <a
          aria-label='LinkedIn'
          className='text-primary'
          href='https://www.linkedin.com/company/planetscale'
          target='_blank'
          rel='noreferrer'
        >
          LinkedIn
        </a>
        <span className='text-decoration' role='presentation'>
          {' '}
          |{' '}
        </span>
        <a
          className='text-primary'
          href='https://www.youtube.com/planetscale'
          rel='me nofollow noopener noreferrer'
          target='_blank'
        >
          YouTube
        </a>
        <span className='text-decoration' role='presentation'>
          {' '}
          |{' '}
        </span>
        <a
          className='text-primary'
          href='https://www.facebook.com/planetscaledata'
          rel='me nofollow noopener noreferrer'
          target='_blank'
        >
          Facebook
        </a>
      </p>

      <DevelopmentOnly>
        <div className='fixed bottom-0 right-0 bg-gray-100 px-1 text-primary dark:bg-gray-800'>
          <span className='sm:hidden'>mo</span>
          <span className='max-sm:hidden md:hidden'>sm</span>
          <span className='max-md:hidden lg:hidden'>md</span>
          <span className='max-lg:hidden xl:hidden'>lg</span>
          <span className='max-xl:hidden'>xl</span>
        </div>
      </DevelopmentOnly>
    </footer>
  )
}

function trackLinkClick(event: React.SyntheticEvent<HTMLAnchorElement, Event>) {
  track('Link Click', { element: 'footer', label: event.currentTarget.text, href: event.currentTarget.href })
}
